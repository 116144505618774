<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mb-2">
              <strong>Form kredit ultra mikro</strong>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-3">
              <a-button class="btn btn-outline-danger float-right" @click="back"
                >Return</a-button
              >
              <a-button
                class="btn btn-outline-success float-right"
                @click="download"
                v-if="action === 'update'"
                >TestExcel</a-button
              >
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="card">
                <div class="card-body">
                  <a-tabs
                    default-active-key="1"
                    :activeKey="activeTabs"
                    @change="callback"
                  >
                    <a-tab-pane key="1" tab="Data Nasabah">
                      <div class="row">
                        <div
                          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2"
                        >
                          Data Nasabah
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="row">
                            <div
                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                            >
                              <span>Nama</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                id="inputdatanasabahnama"
                                class=""
                                v-model="input.datanasabah.nama"
                                placeholder="Nama"
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                            >
                              <span>Alamat</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                id="inputdatanasabahalamat"
                                class=""
                                v-model="input.datanasabah.alamat"
                                placeholder="Alamat"
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                            >
                              <span>KTP no.</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                id="inputdatanasabahktpno"
                                class=""
                                v-model="input.datanasabah.KTP"
                                placeholder="KTP no."
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                            >
                              <span>Pekerjaan</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                id="inputdatanasabahpekerjaan"
                                class=""
                                v-model="input.datanasabah.pekerjaan"
                                placeholder="Pekerjaan"
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                            >
                              <span>Tempat bekerja/ush</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                id="inputdatanasabahtempatbekerja"
                                class=""
                                v-model="input.datanasabah.alamatKANTOR"
                                placeholder="Tempat bekerja/ush"
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                            >
                              <span>No. HP/Telp.</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                id="inputdatanasabahnotelp"
                                class=""
                                v-model="input.datanasabah.TELEPON"
                                placeholder="No. HP/Telp."
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                            >
                              <span>Ibu Kandung</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                id="inputdatanasabahibukandung"
                                class=""
                                v-model="input.datanasabah.namaibukandung"
                                placeholder="Ibu Kandung"
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="row">
                            <div
                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                            >
                              <span>Istri/Suami</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                id="inputdatanasabahistri/suami"
                                class=""
                                v-model="input.datanasabah.psgn_nama"
                                placeholder="Istri/Suami"
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                            >
                              <span>KTP No.</span>
                            </div>
                            <div
                              class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1"
                            >
                              <div class="row">
                                <a-input
                                  v-model="input.datanasabah.psgn_ktp"
                                  id="inputdatanasabahnoktp"
                                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                  placeholder="KTP No."
                                  :readOnly="true"
                                  style="cursor: not-allowed;"
                                ></a-input>
                                <span
                                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                                  >Berlaku</span
                                >
                                <a-input
                                  v-model="input.datanasabah.psgn_berlaku"
                                  id="inputdatanasabahberlakunoktp"
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                  placeholder="Berlaku sampai"
                                  :readOnly="true"
                                  style="cursor: not-allowed;"
                                ></a-input>
                              </div>
                            </div>
                            <div
                              class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                            >
                              <span>Pekerjaan</span>
                            </div>
                            <div
                              class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1"
                            >
                              <div class="row">
                                <a-input
                                  v-model="input.datanasabah.psgn_pekerjaan"
                                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                  id="inputpekerjaan"
                                  placeholder="Pekerjaan"
                                  :readOnly="true"
                                  style="cursor: not-allowed;"
                                ></a-input>
                                <span
                                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                                  >Sejak</span
                                >
                                <a-input
                                  v-model="input.datanasabah.psgn_sejak"
                                  id="inputdatanasabahberlakusejakpekerjaan"
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                  placeholder="Sejak Tahun"
                                  :readOnly="true"
                                  style="cursor: not-allowed;"
                                ></a-input>
                              </div>
                            </div>
                            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                              <span>Tempat bekerja/ush</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2"
                            >
                              <a-input
                                v-model="input.datanasabah.psgn_tempatbekerja"
                                id="inputtempatbekerja"
                                class=""
                                placeholder="Tempat bekerja/ush"
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                            >
                              <span>No. HP/Telp.</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                v-model="input.datanasabah.psgn_telp"
                                id="inputnotelp"
                                class=""
                                placeholder="No. HP/Telp."
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                            >
                              <span>Buku Nikah No.</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                v-model="input.datanasabah.psgn_bukunikah"
                                id="inputdatanasabahnobukunikah"
                                class=""
                                placeholder="Buku Nikah No."
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                            >
                              <span>Alamat surat</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                v-model="input.datanasabah.psgn_alamatsurat"
                                id="inputdatanasabahalamatsurat"
                                class=""
                                placeholder="Alamat surat"
                                :readOnly="true"
                                style="cursor: not-allowed;"
                              ></a-input>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                >
                                  Legalitas usaha
                                </div>
                                <div
                                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-4"
                                >
                                  <div class="row">
                                    <div
                                      class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                    >
                                      <span>- SIUP</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <a-input
                                        id="inputdatanasabahsiup"
                                        class=""
                                        placeholder="- SIUP"
                                        :readOnly="false"
                                      ></a-input>
                                      <!-- style="cursor: not-allowed;" -->
                                      <!-- <a-select style="width: 200px;">
                            <a-select-option value="Ada">Ada</a-select-option>
                            <a-select-option value="Tidak">Tidak</a-select-option>
                            <a-select-option value="Proses">Proses</a-select-option>
                          </a-select> -->
                                    </div>
                                    <div
                                      class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                    >
                                      <span>- TDP</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <a-input
                                        id="inputdatanasabahtdp"
                                        class=""
                                        placeholder="- TDP"
                                        :readOnly="false"
                                      ></a-input>
                                      <!-- style="cursor: not-allowed;" -->
                                    </div>
                                    <div
                                      class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                    >
                                      <span>- NPWP</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <a-input
                                        id="inputdatanasabahnpwp"
                                        class=""
                                        placeholder="- NPWP"
                                        :readOnly="false"
                                      ></a-input>
                                      <!-- style="cursor: not-allowed;" -->
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-4"
                                >
                                  <div class="row">
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                    >
                                      <span>- HO</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <a-input
                                        id="inputdatanasabahho"
                                        class=""
                                        placeholder="- HO"
                                        :readOnly="false"
                                      ></a-input>
                                      <!-- style="cursor: not-allowed;" -->
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                    >
                                      <span>- Suket Usaha</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <a-input
                                        id="inputdatanasabahsuketusaha"
                                        class=""
                                        placeholder="- Suket Usaha"
                                        :readOnly="false"
                                      ></a-input>
                                      <!-- style="cursor: not-allowed;" -->
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                    >
                                      <span>- Lainnya</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <a-input
                                        id="inputdatanasabahlainnya"
                                        class=""
                                        placeholder="- Lainnya"
                                        :readOnly="false"
                                      ></a-input>
                                      <!-- style="cursor: not-allowed;" -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-2"
                                >
                                  Karakter
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <div class="row">
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <center>Kurang(1-4)</center>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <center>Cukup(5-7)</center>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <center>Baik(8-10)</center>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <span>Jujur,Kooperatif,terbuka</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.karakter_jujur"
                                    ref=""
                                    :raw="true"
                                    :options="options.number"
                                    @input="changeInput('karakter')"
                                    placeholder="Masukan Score"
                                  ></Cleave>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                >
                                  <span>- slik</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.karakter_slik"
                                    :style="datadislik.visibleredirect ? 'width: 70%' : ''"
                                    ref=""
                                    @input="changeInput('karakter')"
                                    :placeholder="datadislik.visibleredirect ? 'Tidak ada analisa' : 'Masukan Score'"
                                  ></Cleave>
                                  <a-button v-if="datadislik.visibleredirect" class="btn btn-outline-primary ml-2" @click="redirectToAnalisa">Isi Analisa</a-button>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                >
                                  <span>- kasus hukum</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.karakter_kasus"
                                    ref=""
                                    :raw="true"
                                    :options="options.number"
                                    @input="changeInput('karakter')"
                                    placeholder="Masukan Score"
                                  ></Cleave>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                >
                                  <span>- gaya hidup</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.karakter_gayahidup"
                                    ref=""
                                    :raw="true"
                                    :options="options.number"
                                    @input="changeInput('karakter')"
                                    placeholder="Masukan Score"
                                  ></Cleave>
                                </div>
                                <div
                                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                                >
                                  <span> </span>
                                </div>
                                <div
                                  class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.karakter_score"
                                    :readOnly="true"
                                    placeholder="Total Score"
                                  ></a-input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-2"
                                >
                                  Kemampuan
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <div class="row">
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <center>Kurang(1-4)</center>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <center>Cukup(5-7)</center>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <center>Baik(8-10)</center>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                >
                                  <span>- Strata pendidikan</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.kemampuan_pendidikan"
                                    ref=""
                                    :raw="true"
                                    :options="options.number"
                                    @input="changeInput('kemampuan')"
                                    placeholder="Masukan Score"
                                  ></Cleave>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                >
                                  <span>- Pengalaman usaha</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.kemampuan_pengalaman"
                                    ref=""
                                    :raw="true"
                                    :options="options.number"
                                    @input="changeInput('kemampuan')"
                                    placeholder="Masukan Score"
                                  ></Cleave>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                >
                                  <span>- repayment capasity</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.kemampuan_repayment"
                                    ref=""
                                    :raw="true"
                                    :options="options.number"
                                    @input="changeInput('kemampuan')"
                                    placeholder="Masukan Score"
                                  ></Cleave>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                >
                                  <span>- sarana / teknologi</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.kemampuan_sarana"
                                    ref=""
                                    :raw="true"
                                    :options="options.number"
                                    @input="changeInput('kemampuan')"
                                    placeholder="Masukan Score"
                                  ></Cleave>
                                </div>
                                <div
                                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                                >
                                  <span> </span>
                                </div>
                                <div
                                  class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.kemampuan_score"
                                    ref=""
                                    placeholder="Masukan Score"
                                  ></a-input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div
                                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                >
                                  <div class="row">
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-2"
                                    >
                                      Modal
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <div class="row">
                                        <div
                                          class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                        >
                                          <center>Kurang(1-4)</center>
                                        </div>
                                        <div
                                          class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                        >
                                          <center>Cukup(5-7)</center>
                                        </div>
                                        <div
                                          class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                        >
                                          <center>Baik(8-10)</center>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                    >
                                      <span>ASET</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <Cleave
                                        class="ant-input"
                                        v-model="input.modal_aset"
                                        ref=""
                                        :raw="true"
                                        :options="options.number"
                                        @input="changeInput('modal')"
                                        placeholder="Masukan Score"
                                      ></Cleave>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <span>MODAL SENDIRI</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <Cleave
                                        class="ant-input"
                                        v-model="input.modal_sendiri"
                                        ref=""
                                        :raw="true"
                                        :options="options.number"
                                        @input="changeInput('modal')"
                                        placeholder="Masukan Score"
                                      ></Cleave>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <span>HUTANG</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <Cleave
                                        class="ant-input"
                                        v-model="input.modal_hutang"
                                        ref=""
                                        :raw="true"
                                        :options="options.number"
                                        @input="changeInput('modal')"
                                        placeholder="Masukan Score"
                                      ></Cleave>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <span>PIUTANG</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <Cleave
                                        class="ant-input"
                                        v-model="input.modal_piutang"
                                        ref=""
                                        :raw="true"
                                        :options="options.number"
                                        @input="changeInput('modal')"
                                        placeholder="Masukan Score"
                                      ></Cleave>
                                    </div>
                                    <div
                                      class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                                    >
                                      <span> </span>
                                    </div>
                                    <div
                                      class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1"
                                    >
                                      <a-input
                                        class="ant-input"
                                        v-model="input.modal_score"
                                        ref=""
                                        placeholder="Masukan Score"
                                      ></a-input>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                >
                                  <div class="row">
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-2"
                                    >
                                      Jaminan
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <div class="row">
                                        <div
                                          class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                        >
                                          <center>Kurang(1-4)</center>
                                        </div>
                                        <div
                                          class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                        >
                                          <center>Cukup(5-7)</center>
                                        </div>
                                        <div
                                          class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                        >
                                          <center>Baik(8-10)</center>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <span
                                        style="padding: unset; line-height: 0;"
                                        >Kepemilikan/lokasi/Plat</span
                                      >
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <Cleave
                                        class="ant-input"
                                        v-model="input.jaminan_kepemilikan"
                                        ref=""
                                        :raw="true"
                                        :options="options.number"
                                        @input="changeInput('jaminan')"
                                        placeholder="Masukan Score"
                                      ></Cleave>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <span>usia jaminan</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <Cleave
                                        class="ant-input"
                                        v-model="input.jaminan_usia"
                                        ref=""
                                        :raw="true"
                                        :options="options.number"
                                        @input="changeInput('jaminan')"
                                        placeholder="Masukan Score"
                                      ></Cleave>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <span>kondisi</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <Cleave
                                        class="ant-input"
                                        v-model="input.jaminan_kondisi"
                                        ref=""
                                        :raw="true"
                                        :options="options.number"
                                        @input="changeInput('jaminan')"
                                        placeholder="Masukan Score"
                                      ></Cleave>
                                    </div>
                                    <div
                                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                    >
                                      <span>penggunaan</span>
                                    </div>
                                    <div
                                      class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                    >
                                      <Cleave
                                        class="ant-input"
                                        v-model="input.jaminan_penggunaan"
                                        ref=""
                                        :raw="true"
                                        :options="options.number"
                                        @input="changeInput('jaminan')"
                                        placeholder="Masukan Score"
                                      ></Cleave>
                                    </div>
                                    <div
                                      class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                                    >
                                      <span> </span>
                                    </div>
                                    <div
                                      class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1"
                                    >
                                      <a-input
                                        class=""
                                        v-model="input.jaminan_score"
                                        ref=""
                                        placeholder="Masukan Score"
                                      ></a-input>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3"
                                >
                                  Rata - rata penilaian Scor
                                  <a-input
                                    class="float-right"
                                    v-model="input.rata_score"
                                    ref="rataratapenilaianscore"
                                    placeholder="Rata - rata penilaian score"
                                    style="width: 15em;"
                                  ></a-input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2"
                                >
                                  <span style="font-weight: bold;"
                                    >Penerimaan</span
                                  >
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>Penjualan</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.penerimaan_penjualan"
                                    placeholder="Penjualan"
                                    :raw="true"
                                    :options="options.number"
                                    @input="hitungPendapatanKotor"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>Harga Pokok Penjualan</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.penerimaan_hargapokok"
                                    :raw="true"
                                    :options="options.number"
                                    placeholder="Harga Pokok Penjualan"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>Pendapatan Kotor</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.penerimaan_kotor"
                                    :raw="true"
                                    :options="options.number"
                                    placeholder="Pendapatan Kotor"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1"
                                >
                                  <span style="font-weight: bold;"
                                    >BIAYA - BIAYA</span
                                  >
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1"
                                >
                                  <span style="font-weight: bold;"
                                    >Biaya operasional:
                                  </span>
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>- Biaya Penjualan</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.biayaops_penjualan"
                                    :raw="true"
                                    :options="options.number"
                                    @input="hitungTotalJumlahBiayaOperasional"
                                    placeholder="- Biaya Penjualan"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>- Biaya Umum</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.biayaops_umum"
                                    :raw="true"
                                    :options="options.number"
                                    @input="hitungTotalJumlahBiayaOperasional"
                                    placeholder="- Biaya Umum"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>- Biaya Administrasi</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.biayaops_administrasi"
                                    :raw="true"
                                    :options="options.number"
                                    @input="hitungTotalJumlahBiayaOperasional"
                                    placeholder="- Biaya Administrasi"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>Jumlah Biaya Operasional</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    v-model="input.biayaops_jumlah"
                                    ref="jumlahbiayaoperasional"
                                    :raw="true"
                                    :options="options.number"
                                    placeholder="Jumlah Biaya Operasional"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4"
                                >
                                  <strong>Keterangan</strong>
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2"
                                >
                                  <strong>AKTIVA</strong>
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>Kas/Bank</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.aktiva_kasbank"
                                    @input="hitungHitungKeteranganAktiva"
                                    ref="kasataubank"
                                    placeholder="Kas/Bank"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>Piutang</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.aktiva_piutang"
                                    @input="hitungHitungKeteranganAktiva"
                                    ref="piutang"
                                    placeholder="Piutang"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>Persediaan</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.aktiva_persediaan"
                                    @input="hitungHitungKeteranganAktiva"
                                    ref="persedian"
                                    placeholder="Persediaan"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>Aktiva Lancar</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.aktiva_lancar"
                                    ref="aktivalancar"
                                    placeholder="Aktiva Lancar"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-3"
                                >
                                  <strong>Aktiva Tetap</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.aktiva_tetap"
                                    @input="hitungTotalJumlahAktiva"
                                    ref="aktivatetap"
                                    placeholder="Aktiva Tetap"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-3"
                                >
                                  <strong>Jumlah Aktiva</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.aktiva_jumlah"
                                    ref="jumlahaktiva"
                                    placeholder="Jumlah Aktiva"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-3"
                                >
                                  <strong
                                    >Account Receivable Turnover (ART)</strong
                                  >
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-4"
                                >
                                  <a-input
                                    class=""
                                    ref="art"
                                    v-model="input.art"
                                    placeholder="Account Receivable Turnover (ART)"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                >
                                  <strong>Inventory Turnover (IT)</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <a-input
                                    class=""
                                    ref="it"
                                    v-model="input.it"
                                    placeholder="Inventory Turnover (IT)"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <strong>Account Payble Turnover (APT)</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <a-input
                                    class=""
                                    ref="apt"
                                    v-model="input.apt"
                                    placeholder="Account Payble Turnover (APT)"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <strong>Cost Of Goods Sold (COGSS)</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <a-input
                                    class=""
                                    ref="cogss"
                                    v-model="input.cogs"
                                    placeholder="Cost Of Goods Sold (COGSS)"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <strong>ROI</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <a-input
                                    class=""
                                    ref="roi"
                                    v-model="input.roi"
                                    placeholder="ROI"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="card">
                            <div class="card-body">
                              <div class="row">
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2"
                                >
                                  <span style="font-weight: bold;"
                                    >Biaya hidup</span
                                  >
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>- Keperluan sehari-hari</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.biaya_keperluan"
                                    @input="hitungJumlahBiayaHidup"
                                    placeholder="- Keperluan sehari-hari"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>- Biaya Pendidikan</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    @input="hitungJumlahBiayaHidup"
                                    v-model="input.biaya_pendidikan"
                                    placeholder="- Biaya Pendidikan"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>Jumlah Biaya Hidup</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.biaya_hidup"
                                    placeholder="Jumlah Biaya Hidup"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>JUMLAH BIAYA</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.biaya_jumlah"
                                    placeholder="JUMLAH BIAYA"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>PENDAPATAN KOTOR</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pendapatan_kotor"
                                    placeholder="PENDAPATAN KOTOR"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1"
                                >
                                  <span style="font-wieght: bold;"
                                    >ANGSURAN/KEWAJIBAN LAIN:
                                  </span>
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>- Angsuran kepada BANK</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.angsuran_bank"
                                    @input="hitungPendapatanBersih"
                                    placeholder="- Angsuran kepada BANK"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>JUMLAH ANGSURAN</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.angsuran_bank"
                                    placeholder="JUMLAH ANGSURAN"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                                >
                                  <span>PENDAPATAN BERSIH</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pendapatan_bersih"
                                    placeholder="PENDAPATAN BERSIH"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4"
                                >
                                  <strong>Keterangan</strong>
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2"
                                >
                                  <strong>PASIVA</strong>
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>Hutang Dagang</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pasiva_hutangdagang"
                                    @input="hitungHitungKeteranganPasiva"
                                    ref="hutangdagang"
                                    placeholder="Hutang Dagang"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>Htg Bank J.PDK</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pasiva_hutangpendek"
                                    @input="hitungHitungKeteranganPasiva"
                                    ref="HtgBankJPDK"
                                    placeholder="Htg Bank J.PDK"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>Lainnya</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pasiva_lainnya"
                                    @input="hitungHitungKeteranganPasiva"
                                    ref="lainnya"
                                    placeholder="Lainnya"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>Hutang lancar</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pasiva_hutanglancar"
                                    ref="hutanglancar"
                                    placeholder="Hutang lancar"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <strong>HtgBank J.PJG</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pasiva_hutangpanjang"
                                    @input="hitungTotalHutangPasiva"
                                    ref="hutangjangkapannjang"
                                    placeholder="HtgBankJPJG"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-3"
                                >
                                  <strong>Jumlah Hutang</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pasiva_jumlahhutang"
                                    ref="jumlahhutang"
                                    placeholder="Jumlah Hutang"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-3"
                                >
                                  <strong>Modal</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pasiva_modal"
                                    @input="hitungTotalJumlahPasiva"
                                    ref="modal"
                                    placeholder="Modal"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-3"
                                >
                                  <strong>Jumlah</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-4"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    v-model="input.pasiva_jumlah"
                                    ref="jumlahketeranganpasiva"
                                    placeholder="Jumlah"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                >
                                  <strong>Finasial Needs (FN)</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1"
                                >
                                  <Cleave
                                    class="ant-input"
                                    :raw="true"
                                    :options="options.number"
                                    ref="fn"
                                    v-model="input.fn"
                                    placeholder="Finasial Needs (FN)"
                                    :readOnly="false"
                                  ></Cleave>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <strong>Cash Ratio</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <a-input
                                    class=""
                                    ref="cashratio"
                                    v-model="input.cash_ratio"
                                    placeholder="Cash Ratio"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <strong>Debt Equity Ratio</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <a-input
                                    class=""
                                    ref="debtequityratio"
                                    v-model="input.debt_equity_ratio"
                                    placeholder="Debt Equity Ratio"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <strong>Profit Magin</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <a-input
                                    class=""
                                    ref="ProfitMagin"
                                    v-model="input.profit_margin"
                                    placeholder="Profit Magin"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <strong>ROE</strong>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <a-input
                                    class=""
                                    ref="roe"
                                    v-model="input.roe"
                                    placeholder="ROE"
                                    :readOnly="false"
                                  ></a-input>
                                  <!-- style="cursor: not-allowed;" -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <div class="card">
                            <div class="card-body">
                              <span style="font-weight: bold;"
                                >PENJELASAN / INFORMASI LAIN</span
                              >
                              <a-textarea
                                v-model="input.penjelasan"
                                placeholder="Penjelasan atau informasi lain"
                                allow-clear
                                :auto-size="{ minRows: 3, maxRows: 5 }"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <a-button
                            class="btn btn-outline-primary float-right"
                            style="width: 10%;"
                            @click="activeTabs = '2'"
                            >Next Form</a-button
                          >
                        </div>
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="Form Nota Analisa">
                      <div class="row">
                        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mb-2">
                          <strong>Nota analisis kredit</strong>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="row">
                            <div
                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1"
                            >
                              <span>Nomor</span>
                            </div>
                            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                              <a-input
                                class=""
                                placeholder="Nomor"
                                ref="refnomornota"
                                :readOnly="false"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                            >
                              <span>Dari</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                class=""
                                placeholder="Dari"
                                ref="refdarinota"
                                :readOnly="false"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                            >
                              <span>Perihal</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                class=""
                                placeholder="Perihal"
                                ref="refperihalnota"
                                :readOnly="false"
                              ></a-input>
                            </div>
                          </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                          <div class="row">
                            <div
                              class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                            >
                              <span>Tanggal</span>
                            </div>
                            <div
                              class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                            >
                              <a-input
                                class=""
                                placeholder="Tanggal"
                                style="width: 200px;"
                                ref="reftanggalnota"
                                :readOnly="true"
                              ></a-input>
                            </div>
                            <div
                              class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                            >
                              <span>Kepada</span>
                            </div>
                            <div
                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                            >
                              <a-input
                                class=""
                                placeholder="Kepada"
                                ref="refkepadanota"
                                :readOnly="true"
                              ></a-input>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4"
                        >
                          <div class="row">
                            <div
                              class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            >
                              <strong>Keterangan Permohonan Kredit</strong>
                            </div>
                            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                              <div class="row">
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <span>Jenis Kredit</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                                >
                                  <a-input
                                    class=""
                                    placeholder="Jenis Kredit"
                                    ref="refJenisKreditnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                >
                                  <span>Produk Kredit</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <a-input
                                    class=""
                                    placeholder="Produk Kredit"
                                    ref="refProdukKreditnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                >
                                  <span>Sektor Ekonomi</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <a-input
                                    class=""
                                    placeholder="Sektor Ekonomi"
                                    ref="refSektorEkonominota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                >
                                  <span>No.Reg.Pengajuan</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.kode_pengajuan"
                                    placeholder="No.Reg.Pengajuan"
                                    ref="refNoRegPengajuannota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                              <div class="row">
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-1"
                                >
                                  <span>Baru</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                >
                                  <a-select
                                    class=""
                                    placeholder="Baru"
                                    style="width: 200px;"
                                  >
                                    <a-select-option value="baru"
                                      >Baru</a-select-option
                                    >
                                    <a-select-option value="lama"
                                      >Lama</a-select-option
                                    >
                                  </a-select>
                                </div>
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-1"
                                >
                                  <span>Perubahan(Naik/Turun)</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                                >
                                  <a-select
                                    class=""
                                    placeholder="Perubahan(Naik/Turun)"
                                    style="width: 200px;"
                                  >
                                    <a-select-option value="naik"
                                      >Naik</a-select-option
                                    >
                                    <a-select-option value="turun"
                                      >Turun</a-select-option
                                    >
                                  </a-select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4"
                        >
                          <div class="row">
                            <div
                              class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                            >
                              <strong
                                >ASPEK KELAYAKAN KREDIT (PERHITUNGAN KEBUTUHAN
                                KREDIT)</strong
                              >
                            </div>
                            <div
                              class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2"
                            >
                              <div class="row">
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <span>HPP</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                                >
                                  <a-input
                                    class=""
                                    placeholder="HPP"
                                    ref="refHPPnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                >
                                  <span>Akseptasi Kredit</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.akseptasi_kredit"
                                    placeholder="Akseptasi Kredit"
                                    ref="refAkseptasiKreditnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2"
                                >
                                  <span>Kredit yang dimohon</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                >
                                  <a-input
                                    class=""
                                    placeholder="Kredit yang dimohon"
                                    ref="refKredityangdimohonnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2"
                            >
                              <div class="row">
                                <div
                                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-4"
                                >
                                  <span>Pembulatan</span>
                                </div>
                                <div
                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-4"
                                >
                                  <a-input
                                    class=""
                                    placeholder="Pembulatan"
                                    ref="refPembulatannota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2"
                            >
                              <div class="row">
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <span
                                    >Kapasitas Mengangsur Kewajiban (DSR)</span
                                  >
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.kapasitas_mengangsur"
                                    style="width: 50%;"
                                    placeholder="Kapasitas Mengangsur Kewajiban (DSR)"
                                    ref="refDSRnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <span>Kapasitas Collateral Coverage</span>
                                </div>
                                <div
                                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.kapasitas_collateral"
                                    style="width: 50%;"
                                    placeholder="Kapasitas Collateral Coverage"
                                    ref="refKCCnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2"
                            >
                              <div class="card">
                                <div class="card-body">
                                  <div class="row">
                                    <div
                                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-2"
                                    >
                                      <strong>REKOMENDASI</strong>
                                      <br />
                                      <span class="mt-2"
                                        >Berdasarkan analisis tersebut diatas,
                                        permohonan nasabah diusulkan untuk
                                        disetujui dengan syarat-syarat &
                                        ketentuan sbb.:</span
                                      >
                                    </div>
                                    <div
                                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                    >
                                      <div class="row">
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1"
                                            >
                                              <span>1. Plafond Kredit</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                                            >
                                              <a-input
                                                class=""
                                                placeholder="Plafond Kredit"
                                                ref="refPlafondKreditnota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                            >
                                              <span>2. Suku Bunga</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                placeholder="Suku Bunga"
                                                ref="refSukuBunganota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                            >
                                              <span>3. Jangka Waktu</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                placeholder="Jangka Waktu"
                                                ref="refJangkaWaktunota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                            >
                                              <span>4. Provisi</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <div class="row">
                                                <a-input
                                                  v-model="input.provisi_persen"
                                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                                  placeholder="(Persen)"
                                                  ref="refProvisiPersennota"
                                                  :readOnly="false"
                                                ></a-input>
                                                <a-input
                                                  v-model="
                                                    input.provisi_nominal
                                                  "
                                                  class="col-xs-7 col-sm-7 col-md-7 col-lg-7 ml-2"
                                                  placeholder="Nominal"
                                                  ref="refProvisiNominalnota"
                                                  :readOnly="false"
                                                ></a-input>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                            >
                                              <span>5. Jumlah Pinjaman :</span>
                                            </div>
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                            >
                                              <span class="ml-3">a. Pokok</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.pokok"
                                                placeholder="Pokok (number)"
                                                ref="refPokoknota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                            >
                                              <span class="ml-3">b. Bunga</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.bunga"
                                                placeholder="Bunga (number)"
                                                ref="refBunganota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                            >
                                              <span class="ml-3">Jumlah</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.jumlah"
                                                placeholder="Total Jumlah Pinjaman"
                                                ref="refTJPota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2 pl-3"
                                            >
                                              <span>6. Angsuran/bln</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.angsuran_bulan"
                                                placeholder="Angsuran (number)"
                                                ref="refAngsurannota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-5 col-sm-5 col-md-5 col-lg-5"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                                            >
                                              <span>Pokok</span>
                                            </div>
                                            <div
                                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.angsuran_pokok"
                                                placeholder="Pokok (number)"
                                                ref="refPokoknota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                            <div
                                              class="col-xs-2 col-sm-2 col-md-2 col-lg-2"
                                            >
                                              <span>Bunga</span>
                                            </div>
                                            <div
                                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.angsuran_bunga"
                                                placeholder="Bunga (number)"
                                                ref="refBunganota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-5 col-sm-5 col-md-5 col-lg-5"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2 pl-3"
                                            >
                                              <span>7. Jaminan</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.jaminan"
                                                placeholder="Jaminan"
                                                ref="refJaminannota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                                            >
                                              <span>Nilai Taksasi</span>
                                            </div>
                                            <div
                                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                            >
                                              <a-input
                                                class=""
                                                placeholder="Nilai Taksasi"
                                                ref="refNilaiTaksasinota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                            <div
                                              class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1"
                                            >
                                              <span>Pengikatan</span>
                                            </div>
                                            <div
                                              class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                                            >
                                              <a-input
                                                class=""
                                                placeholder="Pengikatan"
                                                ref="refPengikatannota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2 pl-3"
                                            >
                                              <span>8. Asuransi</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.asuransi"
                                                placeholder="Asuransi (number)"
                                                ref="refAsuransinota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          class="col-xs-7 col-sm-7 col-md-7 col-lg-7"
                                        >
                                          <div class="row">
                                            <div
                                              class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2 pl-3"
                                            >
                                              <span>10. Lainnya</span>
                                            </div>
                                            <div
                                              class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1"
                                            >
                                              <a-input
                                                class=""
                                                v-model="input.lainnya1"
                                                placeholder="Lainnya"
                                                ref="refLainnyanota"
                                                :readOnly="false"
                                              ></a-input>
                                              <a-input
                                                class="mt-1"
                                                v-model="input.lainnya2"
                                                placeholder="Lainnya"
                                                ref="refLainnyanota"
                                                :readOnly="false"
                                              ></a-input>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2 mb-2"
                            >
                              <div class="row">
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                >
                                  <strong>For Signature</strong>
                                </div>
                                <div
                                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                                >
                                  <span>Diusulkan oleh</span>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.signature.diusulkanoleh"
                                    placeholder="Diusulkan oleh"
                                    ref="refDiusulkanOlehnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                                >
                                  <span>Nama ao / Role Name</span>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.signature.namaaoataurolename"
                                    placeholder="Nama ao / Role Name"
                                    ref="refDiusulkanOlehnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                                >
                                  <span>Tanda Tangan</span>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.signature.tandatangan"
                                    placeholder="Tanda Tangan"
                                    ref="refDiusulkanOlehnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                                <div
                                  class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2"
                                >
                                  <span>Tgl Pembuatan</span>
                                </div>
                                <div
                                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1"
                                >
                                  <a-input
                                    class=""
                                    v-model="input.signature.tglpembuatan"
                                    placeholder="Tgl Pembuatan"
                                    ref="refDiusulkanOlehnota"
                                    :readOnly="false"
                                  ></a-input>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2"
                            >
                              <strong>DISPOSISI KOMITE KREDIT</strong>
                              <a-button
                                class="btn btn-outline-primary float-right"
                                @click="openModal('Create')"
                              >
                                <a-icon type="plus" />
                              </a-button>
                              <a-table
                                class="mt-1"
                                size="small"
                                :bordered="true"
                                :columns="columns"
                                :data-source="datatable"
                                :pagination="{
                                  hideOnSinglePage: true,
                                  defaultPageSize: 10,
                                  showQuickJumper: true,
                                  showSizeChanger: true,
                                  showTotal: (total) => `Total ${total} items`,
                                  pageSizeOptions: ['10', '20', '30'],
                                }"
                              >
                                <div
                                  slot="filterDropdown"
                                  slot-scope="{
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters,
                                    column,
                                  }"
                                  style="padding: 8px;"
                                >
                                  <a-input
                                    v-ant-ref="(c) => (searchInput = c)"
                                    :placeholder="`Search ${column.title}`"
                                    :value="selectedKeys[0]"
                                    style="
                                      width: 188px;
                                      margin-bottom: 8px;
                                      display: block;
                                    "
                                    @change="
                                      (e) =>
                                        setSelectedKeys(
                                          e.target.value ? [e.target.value] : []
                                        )
                                    "
                                    @pressEnter="
                                      () =>
                                        handleSearch(
                                          selectedKeys,
                                          confirm,
                                          column.dataIndex
                                        )
                                    "
                                  />
                                  <a-button
                                    type="primary"
                                    icon="search"
                                    size="small"
                                    style="width: 90px; margin-right: 8px;"
                                    @click="
                                      () =>
                                        handleSearch(
                                          selectedKeys,
                                          confirm,
                                          column.dataIndex
                                        )
                                    "
                                  >
                                    Search
                                  </a-button>
                                  <a-button
                                    size="small"
                                    style="width: 90px;"
                                    @click="() => handleReset(clearFilters)"
                                  >
                                    Reset
                                  </a-button>
                                </div>
                                <a-icon
                                  slot="filterIcon"
                                  slot-scope="filtered"
                                  type="search"
                                  :style="{
                                    color: filtered ? '#108ee9' : undefined,
                                  }"
                                />
                                <template
                                  slot="customRender"
                                  slot-scope="text, record, index, column"
                                >
                                  <span
                                    v-if="
                                      searchText &&
                                      searchedColumn === column.dataIndex
                                    "
                                  >
                                    <template
                                      v-for="(fragment,
                                      i) in text
                                        .toString()
                                        .split(
                                          new RegExp(
                                            `(?<=${searchText})|(?=${searchText})`,
                                            'i'
                                          )
                                        )"
                                    >
                                      <mark
                                        v-if="
                                          fragment.toLowerCase() ===
                                          searchText.toLowerCase()
                                        "
                                        :key="i"
                                        class="highlight"
                                        >{{ fragment }}</mark
                                      >
                                      <template v-else>{{
                                        column.dataIndex === "tglregister"
                                          ? moment(
                                              fragment,
                                              "YYYY-MM-DD"
                                            ).format("DD-MM-YYYY")
                                          : fragment
                                      }}</template>
                                    </template>
                                  </span>
                                  <template v-else>
                                    <span> {{ text }}</span>
                                  </template>
                                </template>
                                <template
                                  slot="actions"
                                  slot-scope="text, record, index"
                                >
                                  <a-popconfirm
                                    title="Are you sure？"
                                    ok-text="Yes"
                                    cancel-text="No"
                                    @confirm="deleteFromTable(index)"
                                  >
                                    <a-icon type="delete" class="text-danger"/>
                                  </a-popconfirm>
                                  <a-divider type="vertical" />
                                  <a href="javascript:void(0)" @click="openModal('Edit',record)">
                                    <a-icon type="edit" class="text-warning"/>
                                  </a>
                                </template>
                              </a-table>
                              <a-button
                                class="btn btn-outline-primary float-right mt-2"
                                @click="handleSubmit"
                                >Simpan</a-button
                              >
                              <a-button
                                class="btn btn-outline-warning float-right mr-2 mt-2"
                                style="width: 10%;"
                                @click="activeTabs = '1'"
                                >Previous Form</a-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      v-model="modal.visible"
      :title="modal.action + ' Data Disposisi'"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Batal
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submitToTable"
        >
          {{ modal.action === 'Edit' ? 'Save Changes' : 'Add' }}
        </a-button>
      </template>
      <div class="row">
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
          <strong>Anggota Komite</strong>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <a-input
            class=""
            v-model="modal.input.anggotakomite"
            placeholder="Anggota Komite"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <strong>Deposisi</strong>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            v-model="modal.input.deposisi"
            placeholder="Deposisi"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <strong>Tanda Tangan</strong>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            v-model="modal.input.tandatangan"
            placeholder="Tanda Tangan"
          ></a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
import * as ExcelJS from 'exceljs'

export default {
  components: {
    Cleave,
  },
  created() {
    if (this.dataeditanalisis.id === '' && this.dataeditanalisis !== null) {
      this.action = 'create'
      this.input.datanasabah = this.dataanalisis
      this.input.kode_pengajuan = this.dataanalisis.kode
      // console.log('Tidak Kosong')
      if (this.dataanalisis.analisa_slik !== undefined) {
        if (this.dataanalisis.analisa_slik.length !== 0) {
          this.datadislik.visibleredirect = false
          this.datadislik.dataslik = this.dataanalisis.analisa_slik
          this.input.karakter_slik = this.dataanalisis.analisa_slik[0].skor
        } else {
          this.datadislik.visibleredirect = true
          lou.shownotif('Info', 'Data analisa slik untuk data ini tidak ditemukan!')
        }
      } else {
        this.datadislik.visibleredirect = true
        lou.shownotif('Info', 'Data analisa slik untuk data ini tidak ditemukan!')
      }
    } else {
      this.input = this.dataeditanalisis
      this.input.signature = {
        diusulkanoleh: '',
        namaaoataurolename: '',
        tandatangan: '',
        tglpembuatan: '',
      }
      this.action = 'update'
      console.log('Kosong')
    }
    console.log('this.dataeditanalisis', this.dataeditanalisis)
    console.log('this.dataanalisis', this.dataanalisis)
  },
  data() {
    return {
      loading: false,
      publicPath: process.env.BASE_URL,
      datadislik: {
        visibleredirect: true,
        dataslik: [],
      },
      modal: {
        editdata: {},
        visible: false,
        action: 'Create',
        input: {
          anggotakomite: '',
          deposisi: '',
          tandatangan: '',
        },
      },
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      action: 'create',
      activeTabs: '1',
      dataanalisis: JSON.parse(localStorage.getItem('dataanalisis')),
      dataeditanalisis: JSON.parse(localStorage.getItem('dataeditanalisis')),
      options: {
        number: {
          numeral: true,
        },
      },
      columns: [
        {
          title: 'Actions',
          dataIndex: '#',
          width: 100,
          scopedSlots: {
            customRender: 'actions',
          },
        },
        {
          title: 'Anggota Komite',
          dataIndex: 'anggotakomite',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            moment(record.anggotakomite, 'YYYY-MM-DD')
              .format('DD-MM-YYYY')
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          sorter: (a, b) => a.length - b.length,
        },
        {
          title: 'Deposisi',
          dataIndex: 'deposisi',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            moment(record.deposisi, 'YYYY-MM-DD')
              .format('DD-MM-YYYY')
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          sorter: (a, b) => a.length - b.length,
        },
        {
          title: 'Tanda Tangan',
          dataIndex: 'tandatangan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            moment(record.tandatangan, 'YYYY-MM-DD')
              .format('DD-MM-YYYY')
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          sorter: (a, b) => a.length - b.length,
        },
      ],
      datatable: [],
      input: {
        kode_pengajuan: '',
        karakter_jujur: '',
        karakter_slik: '',
        karakter_kasus: '',
        karakter_gayahidup: '',
        karakter_score: '',
        kemampuan_pendidikan: '',
        kemampuan_pengalaman: '',
        kemampuan_repayment: '',
        kemampuan_sarana: '',
        kemampuan_score: '',
        modal_aset: '',
        modal_sendiri: '',
        modal_hutang: '',
        modal_piutang: '',
        modal_score: '',
        jaminan_kepemilikan: '',
        jaminan_usia: '',
        jaminan_kondisi: '',
        jaminan_penggunaan: '',
        jaminan_score: '',
        rata_score: '',
        penerimaan_penjualan: '',
        penerimaan_hargapokok: '',
        penerimaan_kotor: '',
        biayaops_penjualan: '',
        biayaops_umum: '',
        biayaops_administrasi: '',
        biayaops_jumlah: '',
        biaya_keperluan: '',
        biaya_pendidikan: '',
        biaya_hidup: '',
        biaya_jumlah: '',
        pendapatan_kotor: '',
        angsuran_bank: '',
        pendapatan_bersih: '',
        aktiva_kasbank: '',
        aktiva_piutang: '',
        aktiva_persediaan: '',
        aktiva_lancar: '',
        aktiva_tetap: '',
        aktiva_jumlah: '',
        pasiva_hutangdagang: '',
        pasiva_hutangpendek: '',
        pasiva_lainnya: '',
        pasiva_hutanglancar: '',
        pasiva_hutangpanjang: '',
        pasiva_jumlahhutang: '',
        pasiva_modal: '',
        pasiva_jumlah: '',
        art: '',
        it: '0.21',
        apt: '',
        cogs: '',
        roi: '',
        fn: '',
        cash_ratio: '',
        debt_equity_ratio: '',
        profit_margin: '',
        roe: '',
        penjelasan: '',
        akseptasi_kredit: '',
        kapasitas_mengangsur: '',
        kapasitas_collateral: '',
        provisi_persen: '',
        provisi_nominal: '',
        pokok: '',
        bunga: '',
        jumlah: '',
        angsuran_bulan: '',
        angsuran_pokok: '',
        angsuran_bunga: '',
        jaminan: '',
        asuransi: '',
        lainnya1: '',
        lainnya2: '',
        datanasabah: {
          nama: '',
          alamat: '',
          KTP: '',
          pekerjaan: '',
          alamatKANTOR: '',
          TELEPON: '',
          namaibukandung: '',
          psgn_nama: '',
          psgn_ktp: '',
          psgn_berlaku: '',
          psgn_pekerjaan: '',
          psgn_sejak: '',
          psgn_tempatbekerja: '',
          psgn_telp: '',
          psgn_bukunikah: '',
          psgn_alamatsurat: '',
        },
        signature: {
          diusulkanoleh: '',
          namaaoataurolename: '',
          tandatangan: '',
          tglpembuatan: '',
        },
      },
    }
  },
  methods: {
    moment,
    redirectToAnalisa() {
      // console.log('this.input.datanasabah', this.input.datanasabah)

      localStorage.removeItem('dataeditanalisaslik')
      localStorage.removeItem('dataeditanalisaslik')
      localStorage.setItem('dataeditanalisaslik', JSON.stringify({ id: '' }))
      localStorage.setItem('datanasabahformlain', JSON.stringify(this.input.datanasabah))
      this.$router.push('/analisaslik/form')
    },
    async download(id = '') {
      await lou.getDocumentExcel('master/analisamikro' + (id === '' ? '/' + this.dataeditanalisis.id : '/' + id) + '/template/xlsx')
    },

    async testExcel() {
      // construct a streaming XLSX workbook writer with styles and shared strings
      // console.log('this.publicPath', this.publicPath)
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'Me'
      workbook.lastModifiedBy = 'Her'
      workbook.created = new Date(1985, 8, 30)
      workbook.modified = new Date()
      workbook.lastPrinted = new Date(2016, 9, 27)
      workbook.calcProperties.fullCalcOnLoad = true
      workbook.addWorksheet('DATA NASABAH')

      // Start Editing Sheet Data Nasabah
      const worksheet = workbook.getWorksheet('DATA NASABAH')
      worksheet.mergeCells('A1:P1')
      worksheet.mergeCells('A2:P2')
      worksheet.mergeCells('A3:P3')
      worksheet.mergeCells('C4:H4')
      worksheet.mergeCells('K4:P4')
      worksheet.mergeCells('C5:H5')
      worksheet.mergeCells('K5:M5')
      worksheet.mergeCells('O5:P5')
      worksheet.mergeCells('C6:E6')
      worksheet.mergeCells('K6:M6')
      worksheet.mergeCells('O6:P6')
      worksheet.mergeCells('A7:E7')
      worksheet.mergeCells('F7:G7')
      worksheet.mergeCells('K7:P7')
      worksheet.mergeCells('K8:P8')
      worksheet.mergeCells('A11:H11')
      worksheet.mergeCells('I11:P11')
      worksheet.mergeCells('A15:H15')
      worksheet.mergeCells('I15:P15')
      worksheet.mergeCells('A23:H23')
      worksheet.mergeCells('I23:P23')
      worksheet.mergeCells('A30:O30')
      worksheet.getCell('A1').value = 'FORM KREDIT ULTRA MIKRO'
      worksheet.getCell('A2').value = 'KREDIT MODAL KERJA'
      worksheet.getCell('A3').value = 'DATA NASABAH'

      worksheet.getCell('B5').value = ':'
      worksheet.getCell('B5').value = ':'
      worksheet.getCell('B6').value = ':'
      worksheet.getCell('B7').value = ':'
      worksheet.getCell('B8').value = ':'
      worksheet.getCell('B9').value = ':'
      worksheet.getCell('B10').value = ':'
      worksheet.getCell('J5').value = ':'
      worksheet.getCell('J6').value = ':'
      worksheet.getCell('J7').value = ':'
      worksheet.getCell('J8').value = ':'
      worksheet.getCell('J9').value = ':'
      worksheet.getCell('J10').value = ':'
      worksheet.getCell('A4').value = 'Nama'
      worksheet.getCell('A5').value = 'Alamat'
      worksheet.getCell('A6').value = 'KTP No.'
      worksheet.getCell('A7').value = 'Pekerjaan'
      worksheet.getCell('F7').value = 'Sejak Tahun'
      worksheet.getCell('A8').value = 'Tempat bekerja/ush'
      worksheet.getCell('A9').value = 'No. HP/Telp.'
      worksheet.getCell('A10').value = 'Ibu Kandung'
      worksheet.getCell('I4').value = 'Istri/Suami'
      worksheet.getCell('I5').value = 'KTP No.'
      worksheet.getCell('N5').value = 'Berlaku s/d'
      worksheet.getCell('I6').value = 'Pekerjaan'
      worksheet.getCell('N6').value = 'Sejak Tahun'
      worksheet.getCell('I7').value = 'Tempat Bekerja/Usaha'
      worksheet.getCell('I8').value = 'No. HP/Telp.'
      worksheet.getCell('I9').value = 'Buku Nikah No.'
      worksheet.getCell('I10').value = 'Alamat Surat'
      worksheet.getCell('C4').value = this.input.datanasabah.nama
      worksheet.getCell('C5').value = this.input.datanasabah.alamat
      worksheet.getCell('C6').value = this.input.datanasabah.KTP
      worksheet.getCell('C7').value = this.input.datanasabah.pekerjaan
      // worksheet.getCell('H7').value = this.input.datanasabah.
      worksheet.getCell('C8').value = this.input.datanasabah.alamatKANTOR
      worksheet.getCell('C9').value = this.input.datanasabah.TELEPON
      worksheet.getCell('C10').value = this.input.datanasabah.namaibukandung
      worksheet.getCell('K4').value = ''
      worksheet.getCell('K5').value = ''
      worksheet.getCell('O5').value = ''

      worksheet.getCell('A11').value = 'Legalitas Usaha'
      worksheet.getCell('I11').value = 'Legalitas Usaha'

      worksheet.getCell('A12').value = '- SIUP'
      worksheet.getCell('A13').value = '- TDP'
      worksheet.getCell('A14').value = '- NPWP'
      worksheet.getCell('I12').value = '- NPWP'
      worksheet.getCell('I13').value = '- Suket Usaha'
      worksheet.getCell('I14').value = '- Lainnya'

      worksheet.getCell('A15').value = 'KARAKTER'
      worksheet.getCell('A16').value = 'Kriteria'
      worksheet.getCell('A18').value = '- Jujur, Kooperatif, terbuka'
      worksheet.getCell('A19').value = '- slik'
      worksheet.getCell('A20').value = '- kasus hukum'
      worksheet.getCell('A21').value = '- gaya hidup'
      worksheet.getCell('I18').value = '- Strata pendidikan'
      worksheet.getCell('I19').value = '- Pengalaman usaha'
      worksheet.getCell('I20').value = '- Repayment capasity'
      worksheet.getCell('I21').value = '- sarana / teknologi'
      worksheet.getCell('E18').value = 'score'
      worksheet.getCell('E19').value = 'score'
      worksheet.getCell('E20').value = 'score'
      worksheet.getCell('E21').value = 'score'
      worksheet.getCell('M18').value = 'score'
      worksheet.getCell('M19').value = 'score'
      worksheet.getCell('M20').value = 'score'
      worksheet.getCell('M21').value = 'score'
      worksheet.getCell('F16').value = 'Kurang'
      worksheet.getCell('G16').value = 'Cukup'
      worksheet.getCell('H16').value = 'Baik'
      worksheet.getCell('F17').value = '1 - 4'
      worksheet.getCell('G17').value = '5 - 7'
      worksheet.getCell('H17').value = '8 - 10'
      worksheet.getCell('I15').value = 'KEMAMPUAN'
      worksheet.getCell('I16').value = 'Kriteria'
      worksheet.getCell('N16').value = 'Kurang'
      worksheet.getCell('O16').value = 'Cukup'
      worksheet.getCell('P16').value = 'Baik'
      worksheet.getCell('N17').value = '1 - 4'
      worksheet.getCell('O17').value = '5 - 7'
      worksheet.getCell('P17').value = '8 - 10'
      var karakterjujur = this.gachaKordinat(this.input.karakter_jujur)
      worksheet.getCell(karakterjujur + '18').value = this.input.karakter_jujur
      var karakterslik = this.gachaKordinat(this.input.karakter_slik)
      worksheet.getCell(karakterslik + '19').value = this.input.karakter_slik
      var karakterkasus = this.gachaKordinat(this.input.karakter_kasus)
      worksheet.getCell(karakterkasus + '20').value = this.input.karakter_kasus
      var karaktergayahidup = this.gachaKordinat(this.input.karakter_gayahidup)
      worksheet.getCell(
        karaktergayahidup + '21',
      ).value = this.input.karakter_gayahidup
      var kemampuanpendidikan = this.gachaKordinat(
        this.input.kemampuan_pendidikan,
        'right',
      )
      worksheet.getCell(
        kemampuanpendidikan + '18',
      ).value = this.input.kemampuan_pendidikan
      var kemampuanpengalaman = this.gachaKordinat(
        this.input.kemampuan_pengalaman,
        'right',
      )
      worksheet.getCell(
        kemampuanpengalaman + '19',
      ).value = this.input.kemampuan_pengalaman
      var kemampuanrepay = this.gachaKordinat(
        this.input.kemampuan_repayment,
        'right',
      )
      worksheet.getCell(
        kemampuanrepay + '20',
      ).value = this.input.kemampuan_repayment
      var kemampuansarana = this.gachaKordinat(
        this.input.kemampuan_sarana,
        'right',
      )
      worksheet.getCell(
        kemampuansarana + '21',
      ).value = this.input.kemampuan_sarana

      worksheet.getCell('A23').value = 'KARAKTER'
      worksheet.getCell('A24').value = 'Kriteria'
      worksheet.getCell('A26').value = 'ASET'
      worksheet.getCell('A27').value = 'MODAL SENDIRI'
      worksheet.getCell('A28').value = 'HUTANG'
      worksheet.getCell('A29').value = 'PIUTANG'
      worksheet.getCell('I26').value = 'Kepemilikan / lokasi / Plat Nomor'
      worksheet.getCell('I27').value = 'usia jaminan'
      worksheet.getCell('I28').value = 'kondisi'
      worksheet.getCell('I29').value = 'penggunaan'
      worksheet.getCell('E26').value = 'score'
      worksheet.getCell('E27').value = 'score'
      worksheet.getCell('E28').value = 'score'
      worksheet.getCell('E29').value = 'score'
      worksheet.getCell('M26').value = 'score'
      worksheet.getCell('M27').value = 'score'
      worksheet.getCell('M28').value = 'score'
      worksheet.getCell('M29').value = 'score'
      worksheet.getCell('F24').value = 'Kurang'
      worksheet.getCell('G24').value = 'Cukup'
      worksheet.getCell('H24').value = 'Baik'
      worksheet.getCell('F25').value = '1 - 4'
      worksheet.getCell('G25').value = '5 - 7'
      worksheet.getCell('H25').value = '8 - 10'
      worksheet.getCell('I23').value = 'KEMAMPUAN'
      worksheet.getCell('I24').value = 'Kriteria'
      worksheet.getCell('N24').value = 'Kurang'
      worksheet.getCell('O24').value = 'Cukup'
      worksheet.getCell('P24').value = 'Baik'
      worksheet.getCell('N25').value = '1 - 4'
      worksheet.getCell('O25').value = '5 - 7'
      worksheet.getCell('P25').value = '8 - 10'
      worksheet.getCell('A31').value = 'Rata - rata penilaian score'

      // Calculation
      worksheet.getCell('E22').value = this.input.karakter_score
      worksheet.getCell('M22').value = this.input.kemampuan_score
      worksheet.getCell('E30').value = this.input.modal_score
      worksheet.getCell('M30').value = this.input.jaminan_score
      worksheet.getCell('P31').value = this.input.rata_score

      worksheet.getCell('A32').value = 'PENERIMAAN'
      worksheet.getCell('I32').value = 'BIAYA HIDUP'
      worksheet.getCell('A33').value = 'Penjualan'
      worksheet.getCell('A34').value = 'Harga Pokok Penjualan'
      worksheet.getCell('A35').value = 'Pendapatan Kotor'
      worksheet.getCell('A36').value = 'BIAYA-BIAYA'
      worksheet.getCell('A37').value = 'Biaya Operasional: '
      worksheet.getCell('A38').value = '- Biaya Penjualan'
      worksheet.getCell('A39').value = '- Biaya Umum'
      worksheet.getCell('A40').value = '- Biaya Administrasi'
      worksheet.getCell('A41').value = 'Jumlah Biaya Operasional'
      worksheet.getCell('F33').value = this.input.penerimaan_penjualan
      worksheet.getCell('F34').value = this.input.penerimaan_hargapokok
      worksheet.getCell('F35').value = this.input.pendapatan_kotor
      worksheet.getCell('F38').value = this.input.biayaops_penjualan
      worksheet.getCell('F39').value = this.input.biayaops_umum
      worksheet.getCell('F40').value = this.input.biayaops_administrasi
      worksheet.getCell('F41').value = this.input.biayaops_jumlah
      worksheet.getCell('I33').value = '- Keperluan sehari-hari'
      worksheet.getCell('I34').value = '- Biaya Pendidikan'
      worksheet.getCell('I35').value = 'Jumlah Biaya Hidup'
      worksheet.getCell('I36').value = 'JUMLAH BIAYA'
      worksheet.getCell('I37').value = 'PENDAPATAN KOTOR'
      worksheet.getCell('I38').value = 'ANGSURAN/KEWAJIBAN LAIN:'
      worksheet.getCell('I39').value = '- Angsuran kepada BANK'
      worksheet.getCell('I40').value = 'JUMLAH ANGSURAN'
      worksheet.getCell('I41').value = 'PENDAPATAN BERSIH'
      worksheet.getCell('I33').value = this.input.biaya_keperluan
      worksheet.getCell('I34').value = this.input.biaya_pendidikan
      worksheet.getCell('I35').value = this.input.biaya_hidup
      worksheet.getCell('I36').value = this.input.biaya_jumlah
      worksheet.getCell('I37').value = this.input.pendapatan_kotor
      worksheet.getCell('I39').value = this.input.angsuran_bank
      worksheet.getCell('I40').value = this.input.angsuran_bank
      worksheet.getCell('I41').value = this.input.pendapatan_bersih

      worksheet.getCell('A43').value = 'Keterangan'
      worksheet.getCell('I43').value = 'Keterangan'
      worksheet.getCell('A44').value = 'AKTIVA'
      worksheet.getCell('I44').value = 'PASIVA'
      worksheet.getCell('A45').value = 'Kas/Bank'
      worksheet.getCell('A46').value = 'Piutang'
      worksheet.getCell('A47').value = 'Persediaan'
      worksheet.getCell('A48').value = 'Aktiva Lancar'
      worksheet.getCell('A50').value = 'Aktiva Tetap'
      worksheet.getCell('A52').value = 'Jumlah Aktiva'
      worksheet.getCell('I45').value = 'Hutang Dagang'
      worksheet.getCell('I46').value = 'Htg Bank J.PDK'
      worksheet.getCell('I47').value = 'Lainnya'
      worksheet.getCell('I48').value = 'Hutang Lancar'
      worksheet.getCell('I49').value = 'Htg Bank J. PJG'
      worksheet.getCell('I50').value = 'Jumlah Hutang'
      worksheet.getCell('I51').value = 'Modal'
      worksheet.getCell('I52').value = 'Jumlah'
      worksheet.getCell('N45').value = this.input.pasiva_hutangdagang
      worksheet.getCell('N46').value = this.input.pasiva_hutangpendek
      worksheet.getCell('N47').value = this.input.pasiva_lainnya
      worksheet.getCell('N48').value = this.input.pasiva_hutanglancar
      worksheet.getCell('N49').value = this.input.pasiva_hutangpanjang
      worksheet.getCell('N50').value = this.input.pasiva_jumlahhutang
      worksheet.getCell('N51').value = this.input.pasiva_modal
      worksheet.getCell('N52').value = this.input.pasiva_jumlah

      worksheet.getCell('A54').value = 'Account Receivable Turnover (ART)'
      worksheet.getCell('A55').value = 'Inventory Turnover (IT)'
      worksheet.getCell('A56').value = 'Account Payble Turnover (APT)'
      worksheet.getCell('A57').value = 'Cost Of Goods Sold (COGSS)'
      worksheet.getCell('A58').value = 'ROI'
      worksheet.getCell('I54').value = 'Finasial Needs (FN)'
      worksheet.getCell('I55').value = 'Cash Ratio'
      worksheet.getCell('I56').value = 'Debt Equity Ratio'
      worksheet.getCell('I57').value = 'Profit Magin'
      worksheet.getCell('I58').value = 'ROE'
      worksheet.getCell('A59').value = 'PENJELASAN / INFORMASI LAIN'
      worksheet.getCell('A60').value = this.input.penjelasan

      // console.log('sheet', sheet)

      // Finalizing Downloading File
      await workbook.xlsx.writeBuffer('export.xlsx').then(function (data) {
        console.log('file is written')
        var blob = new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        var fileURL = window.URL.createObjectURL(blob)
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', 'export.xlsx')
        document.body.appendChild(fileLink)

        fileLink.click()
      })
    },

    gachaKordinat(value, align = 'left') {
      var ret = ''
      if (align === 'left') {
        if (value <= 4) {
          ret = 'F'
        } else if (value <= 7) {
          ret = 'G'
        } else {
          ret = 'H'
        }
      } else if (align === 'right') {
        if (value <= 4) {
          ret = 'N'
        } else if (value <= 7) {
          ret = 'O'
        } else {
          ret = 'P'
        }
      }
      return ret
    },

    changeInput(from) {
      // console.log('from', from)
      if (from === 'karakter') {
        var karakterjujur =
          this.input.karakter_jujur === ''
            ? 0
            : parseFloat(this.input.karakter_jujur)
        var karakterslik =
          this.input.karakter_slik === ''
            ? 0
            : parseFloat(this.input.karakter_slik)
        var karakterkasus =
          this.input.karakter_kasus === ''
            ? 0
            : parseFloat(this.input.karakter_kasus)
        var karaktergayahidup =
          this.input.karakter_gayahidup === ''
            ? 0
            : parseFloat(this.input.karakter_gayahidup)
        var totalscorekarakter =
          (karakterjujur + karakterslik + karakterkasus + karaktergayahidup) /
          4
        this.input.karakter_score = isNaN(totalscorekarakter)
          ? '0.0'
          : totalscorekarakter.toFixed(2)
      } else if (from === 'kemampuan') {
        var kemampuanpendidikan =
          this.input.kemampuan_pendidikan === ''
            ? 0
            : parseFloat(this.input.kemampuan_pendidikan)
        var kemampuanpengalaman =
          this.input.kemampuan_pengalaman === ''
            ? 0
            : parseFloat(this.input.kemampuan_pengalaman)
        var kemampuanrepayment =
          this.input.kemampuan_repayment === ''
            ? 0
            : parseFloat(this.input.kemampuan_repayment)
        var kemampuansarana =
          this.input.kemampuan_sarana === ''
            ? 0
            : parseFloat(this.input.kemampuan_sarana)
        var totalscorekemampuan =
          kemampuanpendidikan +
          kemampuanpengalaman +
          kemampuanrepayment +
          kemampuansarana / 4
        this.input.kemampuan_score = isNaN(totalscorekemampuan)
          ? '0.0'
          : totalscorekemampuan.toFixed(2)
      } else if (from === 'modal') {
        var modalaset =
          this.input.modal_aset === '' ? 0 : parseFloat(this.input.modal_aset)
        var modalsendiri =
          this.input.modal_sendiri === ''
            ? 0
            : parseFloat(this.input.modal_sendiri)
        var modalhutang =
          this.input.modal_hutang === ''
            ? 0
            : parseFloat(this.input.modal_hutang)
        var modalpiutang =
          this.input.modal_piutang === ''
            ? 0
            : parseFloat(this.input.modal_piutang)
        var totalscoremodal =
          modalaset + modalsendiri + modalhutang + modalpiutang / 4
        this.input.modal_score = isNaN(totalscoremodal)
          ? '0.0'
          : totalscoremodal.toFixed(2)
        // console.log('totalscoremodal', totalscoremodal)
      } else if (from === 'jaminan') {
        var jaminankepemilikan =
          this.input.jaminan_kepemilikan === ''
            ? 0
            : parseFloat(this.input.jaminan_kepemilikan)
        var jaminanusia =
          this.input.jaminan_usia === ''
            ? 0
            : parseFloat(this.input.jaminan_usia)
        var jaminankondisi =
          this.input.jaminan_kondisi === ''
            ? 0
            : parseFloat(this.input.jaminan_kondisi)
        var jaminanpenggunaan =
          this.input.jaminan_penggunaan === ''
            ? 0
            : parseFloat(this.input.jaminan_penggunaan)
        var totalscorejaminan =
          jaminankepemilikan +
          jaminanusia +
          jaminankondisi +
          jaminanpenggunaan / 4
        this.input.jaminan_score = isNaN(totalscorejaminan)
          ? '0.0'
          : totalscorejaminan.toFixed(2)
      } else {
        console.log('Input is not exist!')
      }
      this.setRataRata()
    },
    setRataRata() {
      var karakter =
        this.input.karakter_score === ''
          ? 0
          : parseFloat(this.input.karakter_score)
      var kemampuan =
        this.input.kemampuan_score === ''
          ? 0
          : parseFloat(this.input.kemampuan_score)
      var modal =
        this.input.modal_score === '' ? 0 : parseFloat(this.input.modal_score)
      var jaminan =
        this.input.jaminan_score === ''
          ? 0
          : parseFloat(this.input.jaminan_score)
      var total = (karakter + kemampuan + modal + jaminan) / 4
      this.input.rata_score = isNaN(total) ? '0.0' : total.toFixed(2)
    },
    hitungPendapatanKotor() {
      var dasar = parseFloat(this.input.penerimaan_penjualan)
      var persen = 80 / 100
      // console.log('persen', persen)
      // console.log('dasar', dasar)
      this.input.penerimaan_kotor = dasar - dasar * persen
      this.input.penerimaan_hargapokok =
        dasar - parseFloat(this.input.penerimaan_kotor)
    },
    hitungTotalJumlahBiayaOperasional() {
      var biayaopspenjualan =
        this.input.biayaops_penjualan === ''
          ? 0
          : parseFloat(this.input.biayaops_penjualan)
      var biayaopsumum =
        this.input.biayaops_umum === ''
          ? 0
          : parseFloat(this.input.biayaops_umum)
      var biayaopsadministrasi =
        this.input.biayaops_administrasi === ''
          ? 0
          : parseFloat(this.input.biayaops_administrasi)
      var total = biayaopspenjualan + biayaopsumum + biayaopsadministrasi
      this.input.biayaops_jumlah = total
    },
    hitungJumlahBiayaHidup() {
      var biayakeperluan =
        this.input.biaya_keperluan === ''
          ? 0
          : parseFloat(this.input.biaya_keperluan)
      var biayapendidikan =
        this.input.biaya_pendidikan === ''
          ? 0
          : parseFloat(this.input.biaya_pendidikan)
      var jumlahbiayaops =
        this.input.biayaops_jumlah === ''
          ? 0
          : parseFloat(this.input.biayaops_jumlah)
      var penerimaankotor =
        this.input.penerimaan_kotor === ''
          ? 0
          : parseFloat(this.input.penerimaan_kotor)
      var total = jumlahbiayaops + biayakeperluan + biayapendidikan
      this.input.biaya_hidup = biayakeperluan + biayapendidikan
      this.input.biaya_jumlah = total
      this.input.pendapatan_kotor = penerimaankotor - total
    },
    hitungPendapatanBersih() {
      var pendapatankotor =
        this.input.pendapatan_kotor === ''
          ? 0
          : parseFloat(this.input.pendapatan_kotor)
      var angsuranbanklain =
        this.input.angsuran_bank === ''
          ? 0
          : parseFloat(this.input.angsuran_bank)
      var total = pendapatankotor - angsuranbanklain
      this.input.pendapatan_bersih = total
    },
    hitungHitungKeteranganAktiva() {
      var aktivakasbank =
        this.input.aktiva_kasbank === ''
          ? 0
          : parseFloat(this.input.aktiva_kasbank)
      var aktivapiutang =
        this.input.aktiva_piutang === ''
          ? 0
          : parseFloat(this.input.aktiva_piutang)
      var aktivapersediaan =
        this.input.aktiva_persediaan === ''
          ? 0
          : parseFloat(this.input.aktiva_persediaan)
      var total = aktivakasbank + aktivapiutang + aktivapersediaan
      this.input.aktiva_lancar = total
    },
    hitungTotalJumlahAktiva() {
      var aktivalancar =
        this.input.aktiva_lancar === ''
          ? 0
          : parseFloat(this.input.aktiva_lancar)
      var aktivatetap =
        this.input.aktiva_tetap === ''
          ? 0
          : parseFloat(this.input.aktiva_tetap)
      var total = aktivalancar + aktivatetap
      this.input.aktiva_jumlah = total
    },
    hitungHitungKeteranganPasiva() {
      var pasivahutangdagang =
        this.input.pasiva_hutangdagang === ''
          ? 0
          : parseFloat(this.input.pasiva_hutangdagang)
      var pasivahutangpendek =
        this.input.pasiva_hutangpendek === ''
          ? 0
          : parseFloat(this.input.pasiva_hutangpendek)
      var pasivalainnya =
        this.input.pasiva_lainnya === ''
          ? 0
          : parseFloat(this.input.pasiva_lainnya)
      var total = pasivahutangdagang + pasivahutangpendek + pasivalainnya
      this.input.pasiva_hutanglancar = total
    },
    hitungTotalHutangPasiva() {
      var pasivahutangpanjang =
        this.input.pasiva_hutangpanjang === ''
          ? 0
          : parseFloat(this.input.pasiva_hutangpanjang)
      var pasivahutanglancar =
        this.input.pasiva_hutanglancar === ''
          ? 0
          : parseFloat(this.input.pasiva_hutanglancar)
      var total = pasivahutangpanjang + pasivahutanglancar
      this.input.pasiva_jumlahhutang = total
    },
    hitungTotalJumlahPasiva() {
      var pasivamodal =
        this.input.pasiva_modal === ''
          ? 0
          : parseFloat(this.input.pasiva_modal)
      var pasivajumlahhutang =
        this.input.pasiva_jumlahhutang === ''
          ? 0
          : parseFloat(this.input.pasiva_jumlahhutang)
      var total = pasivamodal + pasivajumlahhutang
      this.input.pasiva_jumlah = total
    },
    async handleSubmit() {
      var fd = this.input
      delete fd.datanasabah
      delete fd.signature
      var res = null
      if (this.action === 'create') {
        res = await lou.customUrlPost2('analisamikro', fd)
      } else {
        res = await lou.customUrlPut2(
          'analisamikro/' + this.dataeditanalisis.id,
          fd,
        )
      }
      if (res) {
        this.download(res.data)
        this.back()
      }
      console.log('res', res)
    },
    openModal(action = 'Create', editdata = {}) {
      this.resetModal()
      // console.log('this.modal.visible', this.modal.visible)
      this.modal.visible = true
      this.modal.action = action
      // console.log('this.modal.actionasdfads', this.modal.action)
      if (action === 'Edit') {
        this.modal.editdata = editdata
        this.modal.input = editdata
      }
    },
    submitToTable() {
      // console.log('this.modal.action', this.modal.action)
      if (this.modal.action === 'Create') {
        var fd = this.modal.input
        var uuid = this.$uuid.v4()
        fd.id = uuid
        this.datatable.push(fd)
        this.resetModal()
        // console.log('this.datatable', this.datatable)
      } else if (this.modal.action === 'Edit') {
        var target = this.datatable.findIndex((x) => x.id === this.modal.editdata.id)
        console.log('target', target)
        this.datatable[target] = this.modal.input
        this.resetModal()
      }
    },
    resetModal() {
      this.modal = {
        editdata: {},
        visible: false,
        action: 'Create',
        input: {
          anggotakomite: '',
          deposisi: '',
          tandatangan: '',
        },
      }
    },
    deleteFromTable(index) {
      this.datatable.splice(index, 1)
    },
    handleCancel() {
      this.modal.visible = false
    },
    handleSearch(value) {
      fetch(value, (data) => (this.data = data))
    },
    back() {
      this.$router.go(-1)
    },
    callback(key) {
      this.activeTabs = key
      // console.log(key)
      // console.log('activeKey', this.activeTabs)
    },
    format(string) {
      return string.replace(/^(?=.*?[1-9])[0-9()-]+$/gm, '')
    },
  },
}
</script>

<style></style>
